<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-07-26 14:16:48
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-26 18:04:55
-->
<template>
  <a-modal title="选择员工" style="top: 8px" width="70%" v-model="visible" :footer="null" :maskClosable="false">
    <div>
      <a-table
        :loading="tableLoading"
        @change="changeTable"
        :customRow="changeTableRow"
        :data-source="tableData"
        :row-selection="rowSelection"
        bordered
        :rowKey="(record) => record.managerId"
        :pagination="page"
      >
        <a-table-column title="用户名" data-index="loginName" align="center"></a-table-column>
        <a-table-column title="姓名" data-index="name" align="center"></a-table-column>
        <a-table-column title="手机号" data-index="mobile" align="center"></a-table-column>
        <a-table-column title="邮箱" data-index="email" align="center"></a-table-column>
        <a-table-column title="所属门店" data-index="storeName" align="center"></a-table-column>
        <a-table-column title="角色" data-index="role" align="center"></a-table-column>
        <a-table-column title="最后登录时间" data-index="lastLoginDate" align="center"></a-table-column>
      </a-table>
    </div>
    <div class="footer_btns">
      <a-button type="default" @click="onCancel">取消</a-button>
      <a-button type="primary" @click="toSubmit">确定</a-button>
    </div>
  </a-modal>
</template>

<script>
import { listDealer } from '../api/DealerCustomerSourceApi'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  data() {
    return {
      visible: false,
      handle: '',
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      tableLoading: false, //表格loading
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],
    }
  },

  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  created() {},
  methods: {
    setRowData(ids, name) {
      this.ids = ids
      this.handle = name
      this.visible = true
      this.getList(0)
    },

    // 关闭弹框
    onCancel() {
      this.visible = false
    },

    // 获取员工数据
    getList(num) {
      this.selectedRows = []
      this.selectedRowKeys = []
      if (num == 1) {
        this.queryParam = {}
      }
      let obj = {
        pageSize: this.page.pageSize,
        pageNumbet: this.page.current,
      }
      this.queryParam.storeId = this.storeId
      const params = Object.assign({}, obj, this.queryParam)
      listDealer(params).then((res) => {
        this.tableData = res.body.records
        this.page.total = res.body.total
      })
    },

    // 更改分页
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },


    // 点击选中
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    // 添加选中员工
    toSubmit() {
      let obj = {}
      if(this.handle == 'hand_over_cus'){
        obj = {
          managerId: this.selectedRows[0].managerId,
          ids: this.ids,
        }
      }else {
        obj = {
          managerId: this.selectedRows[0].managerId,
          storeId: this.selectedRows[0].storeId,
          cusIds: this.ids,
        }
      }
      let url = this.handle == 'hand_over_cus' ? '/api/dealer/source/customerSource/transfer' : '/api/dealer/customer/dealerCustomerInfo/batchHandedOverCustomer'
      this.axios.post(url, obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.$emit('reload')
        }
      })
    },

  },
}
</script>

<style lang='scss' scoped>
.footer_btns {
  margin-top: 20px;
  text-align: right;
}
</style>