/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-15 10:47:11
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-26 14:18:30
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerCustomerSource = params => axios({
    url: '/api/dealer/source/customerSource/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerCustomerSource = params => axios({
    url: '/api/dealer/source/customerSource/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerCustomerSource = params => axios({
    url: '/api/dealer/source/customerSource/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerCustomerSource = params => axios({
    url:'/api/dealer/source/customerSource/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerCustomerSource = params => axios({
    url: '/api/dealer/source/customerSource/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const listDealer = params => axios({
    url: '/api/base/store/dealerStore/listNotChooseStoreManager',
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  })